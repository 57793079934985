import { Controller} from "@hotwired/stimulus"
import autoComplete from "@tarekraafat/autocomplete.js"

export default class extends Controller {
  static targets = ['input']
  static values = { type: Array, endpointUrl: String }

  connect() {
    this.inputTarget.focus()
    
    this.autocomplete = new autoComplete({
      debounce: 300,
      searchEngine: "loose",
      selector: `#${this.inputTarget.id}`,
      threshold: 2,

      data: {
        src: async () => {
          const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
          const response = await fetch(`/beta/searches?term=${encodeURIComponent(this.inputTarget.value)}`, {
            headers: {
              'X-CSRF-Token': csrfToken
            }
          });
          if (response.ok) {
            const data = await response.json();
            // Ensure unique results by using a Set with a custom key
            return [...new Set(data.map(item => JSON.stringify(item)))].map(item => JSON.parse(item));
          }
          return [];
        },
        keys: ["name"],
        cache: false
      },
           
      resultsList: {
        maxResults: 10,
        noResultsText: "No results found",
      },

      resultItem: {
        element: (item, data) => {
          item.innerHTML = `<div class="font-semibold">${data.value.name}</div> ${data.value?.description}`;
        }
      },

      events: {
        input: {
          selection: (event) => {
            Turbo.visit(`/beta/searches/${event.detail.selection.value.gid}`)
          },
          open: () => {
            // Add loading class to input
            this.inputTarget.classList.add('loading');
          },
          close: () => {
            // Remove loading class from input
            this.inputTarget.classList.remove('loading');
          }
        }
      }
    });
  }
}