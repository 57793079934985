import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
  }

  highlight(event) {
    this.element.querySelectorAll("div").forEach((item, _) => { 
      return item.classList.remove("current", "-mx-3", "px-3", "bg-gray-100", "rounded-xl")
    });

    event.currentTarget.parentElement.classList.add("current", "-mx-3", "px-3", "bg-gray-100", "rounded-xl")
  }
}