import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.querySelectorAll("a").forEach((link, index) => { 
      if (window.location.pathname.match(link.pathname)) {
        link.classList.toggle("current")
      }

      if (window.location.pathname == link.pathname) {
        link.classList.toggle("active")
      }
    })
  }
}