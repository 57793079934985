import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() { 
  }

  submitForm() {
    this.element.requestSubmit();
  }
  
  submitButtonClick(e) {
    e.preventDefault();
  }
}