import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {}

  enableEditing(event) {
    event.preventDefault();
    event.target.remove();
    this.element.querySelector("input[disabled]").removeAttribute("disabled")
  }
}