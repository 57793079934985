import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["link"]
  static classes = ["current"]

  connect() {
    this.currentTab = this.linkTargets.find((link) => {
      return link.pathname.startsWith(window.location.pathname.slice(0, 8))
    });
  }

  linkTargetConnected(target) {
    if (target.pathname.startsWith(window.location.pathname.slice(0, 8))) {
      target.classList.add(...this.currentClasses)
    }
  }

  linkTargetDisconnected(target) {
    target.classList.remove(...this.currentClasses)
  }

  highlight(event) {
    this.currentTab.classList.remove(...this.currentClasses)

    this.currentTab = event.currentTarget
    this.currentTab.classList.add(...this.currentClasses)
  }
}